import Link from "next/link";
import Head from "next/head";

const NotFound = () => {
  return (
    <>
      <Head>
        <title>404 Not Found | Purple Lotus</title>
        <meta
          property="og:title"
          content="404 Not Found | Purple Lotus"
          key="title"
        />
        <meta
          name="description"
          content="We couldn't find the page you are looking for."
        />
      </Head>
      <main className="not-found">
        <h1>404 | Not Found</h1>
        <p>We couldn&apos;t find the page you are looking for.</p>
        <Link href="/">
          <button className="button" data-type="primary">
            Return to home
          </button>
        </Link>
      </main>
    </>
  );
};

export default NotFound;
